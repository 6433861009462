import styled from '@emotion/styled'

export const SelectClassContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const SelectClassContents = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const SelectClassCardContainer = styled.div`
  width: 350px;
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background-color: rgb(110, 108, 103, 0.3);
  padding: 10px;

  ul {
    list-style-type: none;
    margin-left: 10px;
  }
`
export const SelectClassRegisterButton = styled.div`
  background-color: #fd7603;
  border: none;
  padding: 5px 25px;
  cursor: pointer;
  width: 50%;
  cursor: pointer;
  text-align: center;
`
