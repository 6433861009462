// module imports
import React, { useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import moment from 'moment'

// local imports
import Layout from 'components/Layout'
import Seo from 'components/General/SEO'
import { REGISTER_USER_TO_CLASS, GET_CLASSES_AS_STUDENT } from 'utils/graphql'
import UserContext from 'context/user/UserContext'
import { useAuth0 } from 'utils/react-auth0-spa'

// style imports
import {
  SelectClassCardContainer,
  SelectClassRegisterButton,
  SelectClassContents,
  SelectClassContainer,
} from 'components/styleComponents/selectYourClassStyles'

const SelectYourClass = (props) => {
  const { t } = useTranslation('selectAClassPage')
  // pull needed variables off of props

  const { purchase } = props?.location?.state ?? { purchase: null }

  // global context/graphql dat
  const { auth0User } = useAuth0()
  const { updateUserFWAProduct } = useContext(UserContext)
  const [registerUserToClass] = useMutation(REGISTER_USER_TO_CLASS)
  //class data - this is what give this component access to the available classes for the users purchased product.
  const { loading: getClassesLoading, data: getClassesData } = useQuery(
    GET_CLASSES_AS_STUDENT,
    {
      variables: {
        input: {
          status: ['OPEN', 'ACTIVE'],
          priceId: purchase?.priceId,
        },
      },
    },
  )

  // function to register a user to a class
  const registerForClass = async (classId) => {
    const { sub, email } = auth0User
    try {
      const response = await registerUserToClass({
        variables: {
          input: {
            classId,
            studentId: sub,
            email: email,
          },
        },
      })

      if (!response.data.registerUserToClass.success) {
        return toast.error(t('duplicateRegistrationMessage'))
      }

      //update the firestore document with the class that's been registered
      await updateUserFWAProduct({
        purchaseId: purchase.purchaseId,
        purchaseDate: purchase.purchaseDate,
        classId,
        productId: purchase.productId,
        productName: purchase.productName,
        priceId: purchase.priceId,
        priceName: purchase.priceName,
        status: purchase.status,
      })
      // update the local firestore object
      // send the user to the account page now that they've picked their class
      navigate('/account/')
    } catch (err) {
      console.error('There was an error: ', err)
    }
  }

  useEffect(() => {
    if (!purchase) toast.error('Could not retrieve class data')
  }, [purchase])

  return (
    <Layout showPipedrive={false}>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      <SelectClassContainer>
        <h1>{t('selectAClassTitle')}</h1>
        <SelectClassContents>
          {getClassesLoading ? (
            <p>{t('loading')}</p>
          ) : (
            getClassesData?.getClasses?.classes.map((oneClass, id) => {
              return oneClass?.students?.length >=
                oneClass?.maxStudents ? null : (
                <SelectClassCardContainer key={id}>
                  <h3>
                    FLUENTWORLDS {oneClass?.category} {oneClass?.language}
                  </h3>
                  <p>
                    <b>{t('startDate')}</b>{' '}
                    {moment(oneClass?.startDate).format('MMMM Do YYYY')}
                  </p>
                  <p>
                    <b>{t('endDate')}</b>{' '}
                    {moment(oneClass?.oneClass?.endDate).format('MMMM Do YYYY')}
                  </p>
                  <br />
                  <b>{t('classTimes')}</b>
                  <ul>
                    <li>
                      {oneClass?.days[0]?.dayOfWeek}{' '}
                      {oneClass?.days[0]?.timeOfDay}
                    </li>
                    <li>
                      {oneClass?.days[1]?.dayOfWeek}{' '}
                      {oneClass?.days[1]?.timeOfDay}
                    </li>
                  </ul>
                  <br />
                  <p>
                    <b>{t('classDescription')}</b>
                    <br /> {oneClass?.description}
                  </p>
                  <br />
                  <p>
                    <b>{t('classStudents')}</b> {oneClass?.students?.length}/
                    {oneClass?.maxStudents}
                  </p>
                  <br />
                  <SelectClassRegisterButton
                    onClick={() => registerForClass(oneClass.id)}
                  >
                    {t('register')}
                  </SelectClassRegisterButton>
                </SelectClassCardContainer>
              )
            })
          )}
        </SelectClassContents>
      </SelectClassContainer>
    </Layout>
  )
}

export default SelectYourClass
